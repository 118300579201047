<template>

    <!-- Upload media -->
    <Upload @needRefreshList="fetchMedia"/>

    <!-- medias list-->
    <h3 style="margin-left:5%; margin-top:15px; ">{{$t('overlays.my_medias')}} :</h3>
    <div class="card_gallery">
        <div class="gallery_column" v-for="medias in columns" :key="medias">
                <div class="choice_card" v-for="media in medias" :key="media._id" @click.stop="selectMedia(media)">
                    <template v-if="/video/.test(media.media_mimetype) && !media.transcoded">
                        <div style="background-color: var(--color2); border-radius: 6px; width:100%; height: 100px; display: flex; flex-direction:column; align-items: center; justify-content: center;">
                            <span class="loading_spinner"></span>
                        </div>
                    </template>
                    <template v-else>
                        <img v-if="media.media_thumbnail" :src="media.media_thumbnail"/>
                        <div class="card_title">
                            <p v-if="media.imported">{{ media.media_name }}</p>
                        </div>
                        <div class="video_duration" v-if="/video/.test(media.media_mimetype)">
                            <p>{{ renderMsShort(media.media_duration) }}</p>
                        </div>
                    </template>
                    <img src="../../../../assets/trash.png" style="height: 15px; width: 15px; position: absolute; right: 5px; top:5px; cursor: pointer; border-radius: 0;" @click.stop="deleteMedia(media)"/>
            </div>
        </div>
    </div>

</template>


<script setup>
//import upload from "./upload.vue"
import axios from "axios"
import { useStore } from "vuex";
import { defineAsyncComponent, defineEmits, ref, onMounted, computed, onBeforeUnmount, inject } from 'vue';
import { renderMsShort } from "../../../../lib/date-time-duration";
import { makeColumns } from "@/components/Composables/columns.js";
import * as VueI18n from "vue-i18n"

const Upload = defineAsyncComponent(() => import("./upload.vue"));

const emit = defineEmits(["mediaSelected"]);

const store = useStore();

const { t } = VueI18n.useI18n();

const Toaster = inject('Toaster');

const medias = ref([]);

const columns = computed(()=>{
    return makeColumns(medias.value)
});


async function fetchMedia(resetList = false){
    
    let mediasFetched = await axios.get('/medias', {
        "params" : {
            "usr_id" : store.getters.getCurrentUsrId,
            "media_type" : "overlay"
        }
    }).then(res =>{
        return res.data
    }).catch(()=>{
        return []
    });

    if (resetList == true){
        medias.value = [];
    };

    medias.value = mediasFetched.reduce(
        (acc, item) => {
            let itemFoundIdx = acc.findIndex(i => i._id == item._id)
            if (itemFoundIdx == -1){
                return [item, ...acc]
            } else {
                //Update item just if item in current list is a video non-transcoded (for update status) 
                if (/video/.test(item.media_mimetype) && acc[itemFoundIdx].transcoded == false){
                    acc[itemFoundIdx] = item;
                }
                return acc
            };
        },
        [...medias.value]
    ).sort((a, b)=>{
        return (new Date(b.uploaded_at)) - new Date(a.uploaded_at);
    });
    

    //Getting thumbnails
    medias.value.forEach(async (media) => {
        if (/video/.test(media.media_mimetype) && media.media_thumbnail_path && media.media_thumbnail == undefined) {
            media.media_thumbnail = await axios({
                methods: "get",
                url: `storage/redirect/${media.media_thumbnail_path}`,
            })
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return null;
            });
        } else if (/image/.test(media.media_mimetype) && media.media_thumbnail == undefined){
            media.media_thumbnail = await axios({
                methods: "get",
                url: `storage/redirect/${media.media_path}`
            })
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                return null;
            });
        }
    })
};



var fetchMediaIntervaler;

onMounted(()=>{
    fetchMedia();

    fetchMediaIntervaler = setInterval(() => {
        fetchMedia();
    }, 5000);
});

onBeforeUnmount(()=>{
    clearInterval(fetchMediaIntervaler)
});

function selectMedia(media){
    if (/video/.test(media.media_mimetype)){
        media.type = "video";
    } else {
        media.type = "image";
    }
    media.origin = "user_media"
    emit('mediaSelected', media)
}

async function deleteMedia(media){
    var mediaUsedInCurrentProject = false;
    if (media.media_links != undefined && media.media_links.length > 0){

        var message = t('Toaster.media_used.message');

        media.media_links.forEach(link =>{
            if (Object.keys(link).includes('project')){
                message += "\n  • " + link.project.project_name
            }

            if (link.project && link.project.project_id == store.state.creativeSpace.project_data._id){
                mediaUsedInCurrentProject = true
            }
        });

        
    } else {
        var message = t('Toaster.will_delete_media')
    }

    const response = await Toaster.prompt({
        title: t('Toaster.areYouSure'),
        message,
        validate: t('Delete'),
        cancel: t('Cancel')
    })

    if (response == "validate") {
        await axios.delete(`/medias/` + media._id).catch(() => {
            Toaster.error()
        });
        fetchMedia(true);

        if (mediaUsedInCurrentProject == true){
            emit('mediaDeleted')
        }
    }


}



</script>